<template>
  <div :class="scrollTop !== 0 ? 'header active' : 'header'">
    <div class="header_top">
      <div class="con">
        <div class="register">
          <span v-if="!hasToken" @click="toRoute('/login')">{{
            $t('components.Header.285632-0')
          }}</span>
          <span v-else
            >Hi，{{
              $store.state.personal && $store.state.personal.personalData.msisdn
            }}
            <b @click="toLogout()">{{
              $t('components.Header.5m6kibfv55k0')
            }}</b>
          </span>
        </div>
        <div>|</div>
        <div class="language">
          <img src="@/assets/images/header/language.svg" alt="" />
          <span
            :class="{ active: language === item.name }"
            :style="{ '--width': language === 'EN' ? '20px' : '28px' }"
            @click="handleLangCommand(item)"
            v-for="item in languageActionList"
            :key="item.value"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="header_bottom">
      <div class="header_bottom_img">
        <!-- zh logo -->
        <img
          @click="toRoute('/')"
          style="cursor: pointer"
          :src="
            $isEnglish
              ? require('@/assets/images/header/logo-en.svg')
              : require('@/assets/images/header/logo.svg')
          "
          alt=""
        />
        <!-- en logo -->
      </div>
      <!-- 抽奖活动 -->
<!--      <div class="lottery" @click="handleToRoute">-->
<!--        <img :src="lotteryCptd()" alt="" />-->
<!--      </div>-->
      <div class="header_bottom_tab">
        <div
          :class="
            $isEnglish ? 'header_bottom_tab_item-en' : 'header_bottom_tab_item'
          "
          v-for="(tab, key) in tabList"
          :key="key"
        >
          <el-dropdown
            v-if="tab.content"
            @command="handleCommandStatic"
            ref="elDropdown"
          >
            <span
              >{{ tab.title }}<i class="el-icon-arrow-down el-icon--right"
            /></span>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item
                style="text-align: center; width: 100px"
                v-for="(item, key) in tab.content"
                :key="key"
                :command="item"
                >{{ item.title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span v-else @click="toRoute(tab.path)"> {{ tab.title }}</span>
        </div>
      </div>
      <div class="header_bottom_active">
        <el-button
          class="active_btn"
          type="primary"
          @click="toRoute('/activation/first')"
          >{{ $t('components.Header.5m6kibfv44w0') }}</el-button
        >
        <el-dropdown @command="handleCommand">
          <span>
            <svg
              style="width: 19px; height: 22px; vertical-align: middle"
              class="icon"
              aria-hidden="true"
            >
              <use xlink:href="#icon-person" />
            </svg>
            <span style="font-size: 16px; vertical-align: middle">{{
              actionName
            }}</span>
            <i
              style="vertical-align: middle"
              class="el-icon-arrow-down el-icon--right"
            />
          </span>
          <el-dropdown-menu slot="dropdown" :append-to-body="false">
            <el-dropdown-item
              style="text-align: center; width: 100px"
              v-for="(i, key) in actionList"
              :key="key"
              :command="i"
              >{{ i.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      :title="$t('components.Header.5m6kibfv58w0')"
      :visible.sync="visible"
      width="30%"
    >
      <span slot="footer" class="dialog-footer">
        <el-button class="del-btn" type="primary" @click="handleLogout">{{
          $t('components.Header.5m6kibfv5bo0')
        }}</el-button>
        <el-button type="primary" @click="visible = false">{{
          $t('components.Header.5m6kibfv5ew0')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { logout } from '@/http/login'
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      iconList: [
        {
          icon: 'send',
          text: this.$t('components.Header.5m6kibfv5hs0')
        },
        {
          icon: 'cost',
          text: this.$t('components.Header.5m6kibfv5ko0')
        },
        {
          icon: 'procedures',
          text: this.$t('components.Header.5m6kibfv5no0')
        }
      ],
      lanageName: this.$t('components.Header.285632-1'),
      lanageList: [this.$t('components.Header.285632-1'), 'English'],
      tabList: [
        {
          // title: this.$t('components.Header.5m6kibfv5tc0'),
          title: this.$t('components.Header.5m6kibfv5tc1'),
          path: '/plans/propagate'
        },
        {
          title: this.$t('components.Header.5m6kibfv5tc2'),
          path: '/plans/global'
        },

        {
          title: this.$t('components.Header.5m6kibfv5wc0'),
          path: '/personal/doubleNumber/publicity'
        },
        {
          title: this.$t('components.Header.5m6kibfv5yw0'),
          path: '/ReferAFriend'
        },
        // 信号覆盖注释
        // {
        //   title: this.$t('components.Header.5m6kibfv6240'),
        //   path: '/coverage'
        // },
        {
          title: this.$t('components.Header.5m6kibfv64w0'),
          path: '/',
          content: [
            {
              title: this.$t('components.Header.5m6kibfv67k0'),
              path: '/helpSupport/commonProblem'
            },
            {
              title: this.$t('components.Header.392339-0'),
              path: '/videoSupport'
            },
            {
              title: this.$t('components.Header.5m6kibfv6240'),
              path: '/coverage'
            },
            {
              title: this.$t('components.Header.5m6kibfv6241'),
              path: '/phone-detection'
            },
            {
              title: this.$t('components.Header.5m6kibfv6ag0'),
              path: '/tracking'
            },
            {
              title: this.$t('components.Header.5m6kibfv6do0'),
              path: '/rates'
            }
          ]
        }
      ],
      actionName: this.$t('components.Header.5m6kibfv6gg0'),
      actionList: [
        {
          name: this.$t('components.Header.5m6kibfv6j40'),
          path: '/personal/myinfo'
        },
        {
          name: this.$t('components.Header.5m6kibfv6nc0'),
          path: '/personal/businessManegement/bundleChange'
        },
        {
          name: this.$t('components.Header.5m6kibfv6ps0'),
          path: '/callrecord'
        }
      ],
      language: this.$t('components.Header.5m6kibfv5qk0'),
      langActive: 'zh',
      languageActionList: [
        {
          name: 'EN',
          value: 'en',
          icon: require('@/assets/images/header/iconen.svg')
        },
        {
          name: this.$t('components.Header.285632-2'),
          value: 'zh',
          icon: require('@/assets/images/header/iconchina.svg')
        },
        {
          name: this.$t('components.Header.285632-3'),
          value: 'tw',
          icon: require('@/assets/images/header/iconchina.svg')
        }
      ],
      visible: false,
      scrollTop: 0
    }
  },
  computed: {
    hasToken() {
      const token = this.$store.getters.token
      if (!token) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.actionName = this.$t('components.Header.5m6kibfv6gg0')
      }
      return token
    }
  },
  mounted() {
    let lang = sessionStorage.getItem('lang') || 'zh'
    this.language =
      lang == 'zh'
        ? this.$t('components.Header.285632-2')
        : lang == 'tw'
        ? this.$t('components.Header.285632-3')
        : 'EN'
    window.addEventListener('scroll', this.handleScroll)
    // desc:下拉菜单错位
    const dropdownMenu = this.$refs['elDropdown'][0].$children[0]
    dropdownMenu.$data.currentPlacement = 'bottom'
  },
  methods: {
    // 抽奖活动图标
    lotteryCptd() {
      const lang = sessionStorage.getItem('lang') || ''
      let url = ''
      switch (lang) {
        case 'zh':
          url = require('@/assets/images/home/choujiang-cn.gif')
          break

        case 'en':
          url = require('@/assets/images/home/choujiang-en.gif')
          break

        case 'tw':
          url = require('@/assets/images/home/choujiang-tw.gif')
          break

        default:
          url = require('@/assets/images/home/choujiang-cn.gif')
          break
      }
      return url
    },
    handleToRoute() {
      window.open(location.origin + '/activity/lottery', '_self')
    },
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
    },
    toRoute(path) {
      const { path: url } = this.$route

      if (path === url) {
        this.$store.commit('SET_FORGET', true)
        this.$store.commit('SET_TITLE_ACTIVE', true)
        return
      }

      if (path.includes('blog')) {
        window.location.href = path
      }

      this.$router.push({
        path
      })
    },
    toLogout() {
      this.$confirm(
        this.$t('components.Header.5m6kibfv6ss0'),
        this.$t('components.Header.5m6kibfv6us0'),
        {
          confirmButtonText: this.$t('components.Header.5m6kibfv5bo0'),
          cancelButtonText: this.$t('components.Header.5m6kibfv5ew0'),
          type: 'warning'
        }
      ).then(() => {
        // 清空弹框和个人中心状态
        sessionStorage.clear('modalVisible')
        this.$store.dispatch('setPersonalData', {})
        this.handleLogout()
      })
    },
    handleLogout() {
      logout().then((res) => {
        if (res.code === '200') {
          this.visible = false
          this.$message.success(res.message)
          this.$store.dispatch('setToken', {})
          removeToken()
          this.hasToken = ''
          this.$router.push({
            path: '/'
          })
        } else {
          this.visible = false
          this.$message.error(res.message)
        }
      })
    },
    handleCommand(i) {
      if (this.hasToken) {
        //已登录状态
        this.actionName = i.name
        this.$router.push({
          path: i.path
        })
      } else {
        this.$router.push({
          path: '/login'
        })
      }
    },
    // 切换中/英
    handleLangCommand(i) {
      this.$setLang(i.value)
      this.language = i.name
    },
    handleCommandStatic(item) {
      this.$router.push({
        path: item.path
      })
    }
  },
  //销毁,否则跳到别的路由还是会出现
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
  background: white;

  &.active {
    box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
  }

  &_top {
    background: #f1f1f1;

    .con {
      height: 34px;
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      gap: 35px;
      align-items: center;
      width: 1400px;
      margin: 0 auto;
      .register {
        cursor: pointer;
        color: #666;
        font-family: 'PingFang HK';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        b {
          &:hover {
            color: #6fad82;
          }
        }
      }
      .language {
        display: flex;
        gap: 14px;
        align-items: center;
        span {
          color: #666;
          font-family: 'PingFang HK';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          position: relative;
        }
        img {
          width: 22px;
          height: 22px;
          margin-right: 14px;
        }
        .active {
          color: #333;
          font-family: 'PingFang HK';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &:after {
            display: block;
            content: '';
            background: #666;
            width: var(--width);
            height: 1px;
            position: absolute;
            bottom: -6px;
            left: 0px;
          }
        }
      }
    }
  }

  &_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
    padding: 12px 0;
    box-sizing: border-box;

    &_active {
      text-align: right;
      min-width: 250px;
      display: flex;
      align-items: center;

      .active_btn {
        width: 124px;
        margin-right: 20px;
        border: none;
        font-size: 16px;
        border-radius: 8px;
        background: linear-gradient(270.52deg, #fca31d 0.59%, #fcba1d 99.76%);
      }
      .line {
        height: 20px;
        border-left: 1px solid rgba(0, 0, 0, 0.85);
        display: inline-block;
        margin: 0 20px;
      }
      .curLanguage {
        display: flex;
        align-items: center;
        img {
          width: 28px;
          height: 28px;
          margin-right: 4px;
        }
      }
    }

    &_img {
      width: auto;
      height: 54px;
    }

    .lottery {
      img {
        cursor: pointer;
        width: auto;
        height: 54px;
      }
    }

    &_tab {
      display: flex;
      align-items: center;
      // width: 760px;
      gap: 40px;
      padding-top: 6px;
      // margin: 0 50px 0 70px;

      &_item {
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;

        &:not(:last-child) {
          position: relative;
          transition: color 0.3s ease;

          &::after {
            width: 0;
            position: absolute;
            left: 50%;
            bottom: -8px;
            height: 4px;
            background: #21a56c;
            border-radius: 8px;
            content: '';
            transform: translateX(-50%);
            transition: all 0.3s ease;
          }

          &:hover,
          &.active {
            position: relative;
            color: #21a56c;
            line-height: 31px;

            &::after {
              width: 16px;
            }
          }
        }

        .el-dropdown {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &_item-en {
        font-size: 18px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        word-break: break-word;
        white-space: normal;
        display: inline-block;
        text-align: center;

        &:not(:last-child) {
          position: relative;
          transition: color 0.3s ease;

          &::after {
            width: 0;
            position: absolute;
            left: 50%;
            bottom: -8px;
            height: 4px;
            background: #21a56c;
            border-radius: 8px;
            content: '';
            transform: translateX(-50%);
            transition: all 0.3s ease;
          }

          &:hover,
          &.active {
            position: relative;
            color: #21a56c;
            line-height: 31px;

            &::after {
              width: 16px;
            }
          }
        }

        .el-dropdown {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    > .el-dropdown {
      width: 128px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 25px;

      span {
        display: flex;
        align-items: center;
        vertical-align: middle;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          vertical-align: middle;
        }

        i.el-icon--right {
          color: #21a56c;
          vertical-align: middle;
        }
      }
    }
  }
}

.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.2) !important;

  ::v-deep.el-dialog {
    border-radius: 8px;

    .el-dialog__header {
      text-align: center;
      padding-top: 60px;
    }

    .el-dialog__headerbtn {
      font-size: 22px;
    }

    .el-dialog__title {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 34px;
    }

    .el-dialog__body {
      padding-top: 24px;
      padding-bottom: 32px;
      padding: 30px 73px;
    }

    .el-dialog__footer {
      text-align: center;

      .el-button {
        width: 168px;
        height: 50px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .txt-single {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  .lineh32 {
    line-height: 32px;
  }

  .del-btn {
    background: none;
    color: #21a56c;
  }
}
</style>
<style lang="scss">
.language {
  .el-dropdown-menu__item {
    display: flex;
    padding: 8px 32px !important;
    align-items: center;
    img {
      width: 28px;
      height: 28px;
      margin-right: 4px;
    }
  }
}
.el-dropdown {
  positive: relative;
  .el-dropdown__popper {
    position: absolute !important;
    top: -20px;
    left: 20px;
  }
}
.el-dropdown-menu {
  position: absolute !important;
  top: 35px !important;
  left: -20px !important;
}
</style>
